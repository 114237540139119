import { FormikHelpers, FormikValues, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { removeEmptyInObject } from '../helpers';
import { toast } from 'react-toastify';
import { IconCheckSquare, IconError } from '../assets/icons';
import { setingsNotification } from '../constants';

type UseActionElementType = {
  elementMutation: any;
  schema: any;
  initialValues: any;
  isAdmin?: boolean;
  id?: string;
  handleClear?: () => void;
  mysteryBoxType?: string;
};

export const checkFile = (form: FormData, file: any, fild: string) => {
  if (file && typeof file !== 'string') form.append(fild, file);
};

export const useActionElement = ({
  schema,
  initialValues,
  id,
  isAdmin = false,
  handleClear = () => {},
  elementMutation,
  mysteryBoxType,
}: UseActionElementType) => {
  const [mobileBanner, setMobileBanner] = useState<any>(null);
  const [mutationLoading, setMutationLoading] = useState(false);
  const [cover, setCover] = useState<any>(null);
  const [propertyBanners, setPropertyBanners] = useState<any[]>([]);
  const [zipFile, setZipFile] = useState<any>(null);
  const [itemsJsonFile, setItemsJsonFile] = useState<any>(null);
  const [coverPreview, setCoverPreview] = useState<any>(null);
  const [banner, setBaneer] = useState<any>(null);
  const [bannerMain, setBannerMain] = useState<any>(null);

  const clearImgs = () => {
    setMobileBanner(null);
    setCover(null);
    setCoverPreview(null);
    setBaneer(null);
    setBannerMain(null);
    setZipFile(null);
    setItemsJsonFile(null);
    setPropertyBanners([]);
  };

  const onSubmitHandler = (
    value: FormikValues,
    { resetForm, validateForm: validate }: FormikHelpers<FormikValues>,
  ): void | Promise<any> => {
    setMutationLoading(true);
    const formData = new FormData();
    checkFile(formData, banner, 'banner');
    checkFile(formData, mobileBanner, 'banner_mobile');
    checkFile(formData, cover, 'logo');
    checkFile(formData, coverPreview, 'logo_preview');
    checkFile(formData, bannerMain, 'banner_main');
    checkFile(formData, zipFile, 'items_zip');
    checkFile(formData, itemsJsonFile, 'items_data');

    if (propertyBanners.length > 0) {
      propertyBanners.forEach((el: any) => {
        checkFile(formData, el, `property_banners`);
      });
    }

    const clearData = removeEmptyInObject(value);

    const {
      banner: bString,
      mobileBanner: mbString,
      cover: cString,
      coverPreview: cCoverPreview,
      bannerMain: cBannerMain,
      zipFile: cZipFile,
      itemsJsonFile: cItemsJsonFile,
      ...rest
    } = clearData;

    formData.append('data', JSON.stringify(rest));

    elementMutation(
      isAdmin
        ? { value: clearData }
        : { formData, ...(id && { id }), ...(mysteryBoxType && { mysteryBoxType }) },
    )
      .unwrap()
      .then(() => {
        toast.success('Creation successful!', {
          ...setingsNotification,
          icon: <IconCheckSquare />,
        });
        resetForm({});
        clearImgs();
        handleClear();
        validate();
        setMutationLoading(false);
      })
      .catch(() => {
        toast.error('Creation error!', { ...setingsNotification, icon: <IconError /> });
        setMutationLoading(false);
      });
  };

  const isValidImg = (arr: any) => {
    return arr.every((element: any) => element);
  };

  const {
    handleSubmit,
    setFieldValue,
    validateForm,
    handleBlur,
    values,
    touched,
    errors,
    isValid,
  } = useFormik({
    validationSchema: schema,
    onSubmit: onSubmitHandler,
    initialValues,
    validateOnMount: true,
  });

  useEffect(() => {
    validateForm();
  }, [mobileBanner, cover, coverPreview, banner, bannerMain, validateForm]);

  return {
    banner,
    bannerMain,
    mobileBanner,
    cover,
    coverPreview,
    values,
    touched,
    errors,
    isValid,
    zipFile,
    itemsJsonFile,
    mutationLoading,
    propertyBanners,
    setPropertyBanners,
    setItemsJsonFile,
    setZipFile,
    setBaneer,
    setBannerMain,
    setMobileBanner,
    setCover,
    setCoverPreview,
    isValidImg,
    handleSubmit,
    setFieldValue,
    handleBlur,
    validateForm,
  };
};
