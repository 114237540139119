/* eslint-disable @typescript-eslint/naming-convention */
export const en = {
  'logo.text': 'Election Violation Monitoring',
  'header.sign_in': 'Sign in',
  'submit.violation': 'Submit violation',
  'map.total': 'Total',
  // homepage
  'home.violation.list': 'Violations list for Voting Point',
  'home.clear': 'CLear selection',
  'home.total': 'Total',
  'home.show_more': 'Show more',
  'home.voting_point': 'Voting point',
  'home.download': 'Download',

  // submitpage
  'submit.tell_us_about': 'Tell us about violation or protocol',
  'submit.next': 'Next',
  'submit.send': 'Send',
  'submit.drop': 'Drop files here',
  'submit.or': 'OR',
  'submit.upload': 'Upload file',
  'submit.region': 'Region',
  'submit.district': 'District',
  'submit.voting': 'Voting point',
  'submit.description': 'Description',
  'submit.your_phone': 'Your phone number',

  // login
  'login.login': 'Login to account',
  'login.signUpGoogle': 'sign up with google',
  'login.signIpGoogle': 'sign in with google',
  'login.text.title.login': 'hello again',
  'login.text.title.register': 'Welcome',
  'login.text.title.restore': 'Don’t give up',
  'login.text.title.confirm': 'Almost there',
  'login.text.descriptions.login':
    'Discover officially licensed NFT collectibles and a great community of fellow collectors',
  'login.text.descriptions.restore':
    'Losing the password may be upsetting yet it’s not the end of the world.',
  'login.text.descriptions.confirm':
    "You're now one step away from restoring access to your LiquidAcre account!",
  'login.notRegistered': 'Not registered yet? ',
  'login.haveAccount': 'Already have an account ? ',
  'login.signUp': 'Sign up',
  'login.form.label': 'Sign in to LiquidAcre',
  // admin
  'admin.violation': 'Violation',
  'admin.protocol': 'Protocol',
  'admin.logout': 'Log Out',
  'admin.signin': 'sign in',
  'admin.return': 'Return',
  'admin.publish': 'Publish',
  'admin.items': 'Items',

  // modals
  'modal.welcome.text1': 'Welcome to LiquidAcre!',
  'modal.welcome.text2': 'Please sign in to continue.',
  'modal.welcome.enterBD': 'To register in LiquidAcre enter your date of birth',
  'modal.welcome.label': 'Date of birth',
  'modal.younger.ok': 'oh... Okay',
  'modal.younger.ooops': 'AGE RESTRICTION',
  'modal.younger.descriptions': 'You must be at least 18 years old to use the LiquidAcre platform.',

  // button
  'button.register': 'register',
  'button.logIn': 'login',
  'button.continueSignUp': 'continue to SIGNing up',
  'button.backToSogIn': 'Back to Sign in',

  // forms

  'form.email.placeholder': 'Email Address',
  'form.email.label': 'login',
  'form.error.notRegistered':
    'Oops...We struggle to find anyone registered with this Email...Please check your data or ',
  'form.forgotPass': 'Forgot password?',
  'form.password': 'Password',
  'form.wrongPassword':
    'Hmm... We remember you but the password seems to be wrong. Check your data or ',

  // footer
  'footer.part1': 'Sonic Cards Collection',
  'footer.part2': 'by Sega × IDW ',

  // footer
  'cards.noCards.title': 'No cards saved yet',
  'cards.noCards.desc':
    'Please go back to “Pay with card tab” and check “Save for future payments” as you pay to have the card saved here. ',
};
